import { defineStore } from 'pinia'

import type { ReleaseInterface } from '@voix/types'

interface ReleasesStoreInterface {
  releases: Array<ReleaseInterface>
  currentPage: number
  lastPage: number
}

export const useReleasesStore = defineStore('releasesStore', {
  state: () =>
    ({
      releases: [],
      currentPage: 1,
      lastPage: 1,
    } as ReleasesStoreInterface),

  actions: {
    setReleases(releases: Array<ReleaseInterface>) {
      this.releases = releases
    },
    setPagination(currentPage: number, lastPage: number) {
      this.currentPage = currentPage
      this.lastPage = lastPage
    },

    updateRelease(release: ReleaseInterface, updatedRelease: ReleaseInterface) {
      const index = this.releases.findIndex(r => r.id === release.id)
      this.releases[index] = updatedRelease
    },

    archiveRelease(release: ReleaseInterface) {
      const index = this.releases.findIndex(r => r.id === release.id)
      // Remove release from releases
      this.releases.splice(index, 1)
    },

  },
})
