import { useAdminStore } from '@voix/store/adminStore'
import { useVoixToast } from '@voix/composables/useVoixToast'
import { useReleasesStore } from '@voix/store/releasesStore'
import { $voixNuxtApi } from '@voix/voix-layer/composables/voixNuxtApi'

interface ReleasesArgs {
  filter: string
  orderBy: Array<object | string>
  first: number
  page: number
}

function setReleasesInStore(response: any) {
  if (response) {
    // Store the page data for use in the app
    const releasesStore = useReleasesStore()
    releasesStore.setReleases(response.data)
    releasesStore.setPagination(
      response.meta.current_page,
      response.meta.last_page,
    )
  }
}

async function getReleases(variables: ReleasesArgs) {
  const adminStore = useAdminStore()
  adminStore.isLoading = true

  if (variables.orderBy) {
    variables.orderBy = variables.orderBy.map((orderBy: object | string) => {
      return `${orderBy.column}:${orderBy.order}`
    })
  }
  const query = new URLSearchParams(variables).toString()
  const request = await $voixNuxtApi(
    `/api/voix/releases?${query.toString()}`,
  )

  setReleasesInStore(request.data.value)

  adminStore.isLoading = false

  return request
}

interface ReleaseArgs {
  id: string
}

async function getRelease(variables: ReleaseArgs) {
  const adminStore = useAdminStore()
  adminStore.isLoading = true

  const request = await $voixNuxtApi(
    `/api/voix/releases/${variables.id}`,
  )

  adminStore.isLoading = false

  return request
}

interface ReleasesUpdateArgs {
  id: string
  label: string
  release_date?: string
  status: 'published' | 'unscheduled' | 'pending' | 'cancelled'
}

async function updateRelease(variables: ReleasesUpdateArgs) {
  const adminStore = useAdminStore()
  const voixToast = useVoixToast()
  adminStore.isLoading = true

  const request = await $voixNuxtApi(
    `/api/voix/releases/${variables.id}`,
    {
      method: 'PUT',
      body: variables,
    },
  )

  adminStore.isLoading = false

  if (request.data.value) {
    voixToast.add(`Release Updated`, 'msg')

    return request
  }
  else {
    throw new Error('Unable to save')
  }
}

async function deleteRelease(variables: { id: string }) {
  const adminStore = useAdminStore()
  const voixToast = useVoixToast()
  adminStore.isLoading = true

  const request = await $voixNuxtApi(
    `/api/voix/releases/${variables.id}`,
    {
      method: 'DELETE',
    },
  )

  adminStore.isLoading = false

  if (request.data.value) {
    voixToast.add(`Release Deleted`, 'msg')

    return request
  }
  else {
    throw new Error('Unable to save')
  }
}

export { getReleases, getRelease, updateRelease, deleteRelease }
